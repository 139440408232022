<template>
  <div class="page vacancies-page">
    <div class="page-content" @scroll="onScroll" ref="page">
      <div class="page-header">
        <div class="page-title">Вакансии</div>
        <div v-if="!isSharedLink" class="page-header-button default-button" @click="$eventBus.emit('create-vacancy-modal-open')">
          <div class="icon icon-new"></div>
          <span>Новая вакансия</span>
        </div>
      </div>
      <div class="tables-container">
        <Tabs :history="true" @select-tab="onSelectTab">
          <Tab name="current" selected="true" textLink="Текущие">
            <Searchbar @input="filterVacancies" name="search" placeholder="Поиск по вакансии, заказчику" class="mt-8 mb-0"/>
            <VacanciesTable
              :show-checkbox="!isSharedLink"
              :loading="loadingCurrentVacancies"
              status="current"
              :vacancies="vacanciesCurrent"
              @change-checkboxes="onChangeCheckboxesInTableCurrent"
              @cancel-vacancy="cancelConfirmModalOpen"
              @share="shareVacancy"
            />
            <v-btn
              v-if="vacanciesCurrent.length > 15"
              @click="scrollToTop"
                   depressed rounded :ripple="false" :class="`back-to-top-button`" width="50" height="50">
              <v-icon color="primary">{{ `mdi-arrow-${scrollTop > 200 ? 'up' : 'down'}-circle` }}</v-icon>
            </v-btn>
          </Tab>
          <Tab name="archive" textLink="Архив" v-if="!isSharedLink">
            <Searchbar @input="filterVacancies" name="search" placeholder="Поиск по вакансии, заказчику" class="mt-8 mb-0"/>
            <VacanciesTable
              :loading="loadingArchiveVacancies"
              status="archive"
              :show-checkbox="true"
              :vacancies="vacanciesArchive"
              @change-checkboxes="onChangeCheckboxesInTableArchive"
              @cancel-vacancy="cancelConfirmModalOpen"
              @share="shareVacancy"
            />
            <v-btn
              v-if="vacanciesArchive.length > 15"
              @click="scrollToTop"
                   depressed rounded :ripple="false" :class="`back-to-top-button`" width="50" height="50">
              <v-icon color="primary">{{ `mdi-arrow-${scrollTop > 200 ? 'up' : 'down'}-circle` }}</v-icon>
            </v-btn>
          </Tab>
        </Tabs>
      </div>
    </div>
    <ControlPanel :items="activeTab === 'current' ? checkedVacancyCurrentIds : checkedVacancyArchiveIds"
                  :control-buttons="activeTab === 'current' ?  controlButtons : controlButtonsArchive"
    />
    <Confirm ref="confirm_remove_modal" message="Вы точно хотите удалить вакансию(-ии)?" @accept="cancelVacancy"/>
    <ShareVacancy ref="share_vacancy_modal" @shared="loadCurrentVacancies(); loadArchiveVacancies()"/>
    <ShareModal ref="share_link_modal"/>
  </div>
</template>
<script>
import VacanciesTable from '@/views/vacancies/VacanciesTable.vue';
import ControlPanel from '@/components/ControlPanel.vue';
import Confirm from '@/components/Confirm';
import ShareVacancy from '@/views/vacancy/ShareModal';
import ShareModal from '@/views/candidates/ShareModal';

export default {
  components: {
    ShareModal,
    ShareVacancy,
    VacanciesTable,
    ControlPanel,
    Confirm
  },
  data() {
    return {
      isSharedLink: window.location.href.split('/').includes('shr'),
      checkedVacancyCurrentIds: [],
      checkedVacancyArchiveIds: [],
      vacanciesCurrent: [],
      vacanciesArchive: [],
      loadingCurrentVacancies: false,
      loadingArchiveVacancies: false,
      vacancyCheckboxes: {},
      filterCurrentVacanciesParams: {
        page: 0,
        limit: 20,
        query: '',
        order: 'desc',
        status: ['1']
      },
      filterArchiveVacanciesParams: {
        page: 0,
        limit: 20,
        query: '',
        order: 'desc',
        status: ['2', '3'],
      },
      itemsLengthCurrentVacancies: 0,
      itemsLengthArchiveVacancies: 0,
      controlButtons: [
        {
          name: 'remove',
          text: 'Удалить',
          handler: (vacancyIds) => {
            this.$refs.confirm_remove_modal.open({ vacancyIds });
          },
        },
        {
          name: 'share',
          text: 'Поделиться',
          handler: (ids) => {
            this.$server.request2('vacancy/createAccessToken', { ids }, (data) => {
              this.$refs.share_link_modal.open({type: 'vacancy', token: data.response})
            });
          },
        },
      ],
      controlButtonsArchive: [
        {
          name: 'restore',
          text: 'Открыть заново',
          icon: 'mdi-reload',
          color: 'green_color',
          handler: (items) => {
            items.forEach((id) => {
              this.$server.request2(`vacancy/open/${id}`, {}, () => {
                this.$eventBus.emit('vacancy-open');
              });
            });
          },
        },
      ],
      activeTab: 'current',
      archiveFirstLoad: false,
      currentFirstLoad: false,
      scrollTop: 0,
      rowHeight: 80,
      headerHeight: 40,
    };
  },
  methods: {
    scrollToTop() {
      this.$refs.page.scrollTo({
        top: this.scrollTop > 200 ? 0 : 1000000,
        behavior: 'smooth'
      });
    },
    onChangeCheckboxesInTableCurrent(ids) {
      this.checkedVacancyCurrentIds = ids;
    },
    onChangeCheckboxesInTableArchive(ids) {
      this.checkedVacancyArchiveIds = ids;
    },
    loadCurrentVacancies() {
      if (this.currentFirstLoad) return;
      this.loadingCurrentVacancies = true;
      this.$server.request2('vacancy/get', this.filterCurrentVacanciesParams, (data) => {
        if (this.filterCurrentVacanciesParams.page > 0) {
          this.vacanciesCurrent = this.vacanciesCurrent.concat(data.response);
        } else {
          this.vacanciesCurrent = data.response;
        }
        this.loadingCurrentVacancies = false;
        this.currentFirstLoad = true;
        this.itemsLengthCurrentVacancies = data.response.length;
      }, () => {
        this.loadingCurrentVacancies = false;
      });
    },
    loadArchiveVacancies() {
      if (this.archiveFirstLoad) return;
      this.loadingArchiveVacancies = true;
      this.$server.request2('vacancy/get', this.filterArchiveVacanciesParams, (data) => {
        if (this.filterArchiveVacanciesParams.page > 0) {
          this.vacanciesArchive = this.vacanciesArchive.concat(data.response);
        } else {
          this.vacanciesArchive = data.response;
        }
        this.loadingArchiveVacancies = false;
        this.archiveFirstLoad = true;
        this.itemsLengthArchiveVacancies = data.response.length;
      }, () => {
        this.loadingArchiveVacancies = false;
      });
    },
    onSelectTab(tabName) {
      this.activeTab = tabName;
      if (this.activeTab === 'current') this.loadCurrentVacancies();
      if (this.activeTab === 'archive') this.loadArchiveVacancies();
    },
    filterVacancies(value) {
      this.$server.request2(
        'vacancy/get',
        {
          query: value,
          status: this.activeTab === 'current' ? '1' : ['2', '3']
        },
        (data) => {
          this.activeTab === 'current' ? this.vacanciesCurrent = data.response : this.vacanciesArchive = data.response;
        });
    },
    onScroll(e) {
      if (this.activeTab === 'current') {
        if (this.loadingCurrentVacancies) return;
        const { scrollTop } = e.target;
        this.scrollTop = scrollTop;
        const rows = Math.ceil((scrollTop - this.headerHeight) / this.rowHeight);
        if (this.vacanciesCurrent.length - rows < 2 / 3 * this.filterCurrentVacanciesParams.limit) {
          if (this.itemsLengthCurrentVacancies > 0) {
            this.filterCurrentVacanciesParams.page++;
            this.currentFirstLoad = false;
            this.loadCurrentVacancies();
          }
        }
      } else {
        if (this.loadingArchiveVacancies) return;
        const { scrollTop } = e.target;
        this.scrollTop = scrollTop;
        const rows = Math.ceil((scrollTop - this.headerHeight) / this.rowHeight);
        if (this.vacanciesArchive.length - rows < 2 / 3 * this.filterArchiveVacanciesParams.limit) {
          if (this.itemsLengthArchiveVacancies > 0) {
            this.filterArchiveVacanciesParams.page++;
            this.archiveFirstLoad = false;
            this.loadArchiveVacancies();
          }
        }
      }
    },
    cancelConfirmModalOpen(vacancyIds) {
      this.$refs.confirm_remove_modal.open({ vacancyIds });
    },
    cancelVacancy(items) {
      items.forEach((id, index) => {
        this.$server.request2(`vacancy/cancel`, {id}, () => {
          if (index === items.length - 1) {
            this.currentFirstLoad = false;
            this.archiveFirstLoad = false;
            this.checkedVacancyCurrentIds = [];
            this.checkedVacancyArchiveIds = [];
            this.itemsLengthCurrentVacancies = 0;
            this.itemsLengthArchiveVacancies = 0;
            this.filterCurrentVacanciesParams.page = 0;
            this.filterArchiveVacanciesParams.page = 0;
            this.$forceUpdate();
            this.loadCurrentVacancies();
            this.loadArchiveVacancies();
          }
        });
      });
    },
    shareVacancy(params) {
      this.$refs.share_vacancy_modal.open(params);
    },
    getVacanciesByToken() {
      this.loadingCurrentVacancies = true;
      this.$server.request2('vacancy/getByToken', {at: this.$route.params.token}, (data)=>{
        this.vacanciesCurrent = data.response;
        this.loadingCurrentVacancies = false;
      }, ()=>{
        this.loadingCurrentVacancies = false;
      })
    }
  },
  mounted() {
    if (this.isSharedLink) {
      this.getVacanciesByToken();
    } else {
      this.activeTab = this.$route.hash.replaceAll('#', '');
      if (this.activeTab === 'current') {
        this.loadCurrentVacancies();
      } else {
        this.loadArchiveVacancies();
      }
    }
  },
  created() {
    this.$eventBus.on('vacancy-create, vacancy-update, vacancy-remove, vacancy-close, vacancy-cancel, vacancy-open', () => {
      this.currentFirstLoad = false;
      this.archiveFirstLoad = false;
      this.loadCurrentVacancies();
      this.loadArchiveVacancies();
    });
  },
  beforeDestroy() {
    this.$eventBus.off('vacancy-create, vacancy-update, vacancy-remove, vacancy-close, vacancy-cancel, vacancy-open');
  },
};
</script>
<style lang="scss">
.page.vacancies-page {
  .page-content {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;

    .tabs, .tabs .tabs-list, .tabs .tabs-list .tab {
      display: flex;
      flex-direction: column;
    }

    .tables-container {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
