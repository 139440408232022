<template>
  <v-data-table
    v-model="checkedItemIds"
    :headers="tableHeaders"
    :items="vacancies"
    class="elevation-0 vacancies-table"
    no-data-text="Вакансий пока нет"
    loading-text="Загрузка вакансий..."
    :show-select="showCheckbox"
    item-key="id"
    :loading="loading"
    hide-default-footer
    @click:row="rowClicked"
    disable-sort
    loader-height="0"
    disable-pagination
  >
    <template v-slot:loading>
      <Loader height="120"/>
    </template>
    <template v-slot:no-data>
      <NoResults v-if="status==='current'">
        <template v-slot:title>Вакансий пока нет</template>
        <template v-slot:text>Простота создания, быстрота наполнения, фокусировка и результаты</template>
      </NoResults>
      <NoResults v-else-if="status === 'archive'">
        <template v-slot:title>В архиве вакансий пока нет</template>
        <template v-slot:text>
          Простота создания, быстрота наполнения, фокусировка и результаты
        </template>
      </NoResults>
    </template>
    <template v-slot:item.data-table-select="{isSelected,select, item}">
      <div style="width: 100%; height: 100%; display: flex; padding-left: 10px" @click.stop v-if="item.access_type !== 'read'">
        <v-simple-checkbox :value="isSelected" @input="select($event)"/>
      </div>
    </template>
    <template v-slot:item.title="{item}">
      <div class="vacancy-title">{{ item.title }}</div>
      <VacancyProgressStagesBlock :vacancy="item"/>
    </template>
    <template v-slot:item.user="{item}">
      <div
        v-if="item.user && item.user.id !== $store.state.user.id"
        class="candidates-list-item_title">{{ item.user.name }} {{ item.user.surname ? item.user.surname : '' }}
      </div>
    </template>
    <template v-slot:item.company="{item}">
      <div class="row_company">
        {{ item.company }}
      </div>
    </template>
    <template v-slot:item.fee="{item}">
      <div class="row_fee" v-if="item.fee > 0">
        <span>{{ sumSeparator(item.fee) }}</span><span class="currency">₽</span>
      </div>
    </template>
    <template v-slot:item.candidates="{item}">
      <div class="vacancy-candidates" v-if="item.candidates.length">
        <div class="vacancy-candidates_list">
          <CandidateMedia
            v-for="(candidate, index) in item.candidates.slice(0, 6)"
            :key="index"
            :candidate="candidate"
          />
        </div>
        <div class="vacancy-candidates_text">
          всего <span class="count">{{ item.candidates.length }}</span>
        </div>
      </div>
      <span v-else class="vacancy-candidates_text">нет кандидатов</span>
    </template>
    <template v-slot:item.more="{item}">
      <PopoverList
        v-if="item.access_type !== 'read' && showCheckbox"
        :control-buttons=" status === 'archive' ? controlButtonsArchive : controlButtons" :item="item"/>
    </template>
  </v-data-table>
</template>

<script>
import VacancyProgressStagesBlock from '@/views/vacancy/VacancyProgressStagesBlock.vue';
import Loader from '@/components/Loader';
import PopoverList from '@/components/PopoverList';
export default {
  name: 'VacanciesTable',
  components: {
    Loader,
    VacancyProgressStagesBlock,
    PopoverList
  },
  props: {
    status: String,
    vacancies: Array,
    loading: Boolean,
    showCheckbox: true
  },
  emits: [
    'change-checkboxes',
    'on-scroll',
    'cancel-vacancy',
    'share',
  ],
  data() {
    return {
      tableHeaders: [
        {
          text: 'Вакансия / Прогресс',
          value: 'title',
        },
        {
          text: 'Владелец',
          value: 'user'
        },
        {
          text: 'Заказчик',
          value: 'company',
        },
        {
          text: 'Гонорар',
          value: 'fee',
        },
        {
          text: 'Кандидаты',
          value: 'candidates',
        },
        {
          align: 'end',
          text: '',
          value: 'more',
        },
      ],
      checkedItemIds: [],
      controlButtons: [
        {
          name: 'remove',
          text: 'Удалить',
          color: 'red_color',
          icon: 'mdi-delete-outline',
          handler: (items) => {
            const vacancyIds = items.map(item=>item.id);
            this.$emit('cancel-vacancy', vacancyIds);
          },
        },
        {
          text: 'Поделиться',
          icon: 'mdi-share-variant-outline',
          color: '',
          handler: (items) => {
            this.$emit('share', { vacancy: items[0] });
          }
        }
      ],
      controlButtonsArchive: [
        {
          name: 'restore',
          text: 'Открыть заново',
          icon: 'mdi-reload',
          color: 'green_color',
          handler: (items) => {
            let ids = items.map(item=>item.id);
            ids.forEach((id) => {
              this.$server.request2(`vacancy/open/${id}`, {}, () => {
                this.$eventBus.emit('vacancy-open');
              });
            });
          },
        },
        {
          text: 'Поделиться',
          icon: 'mdi-share-variant-outline',
          color: '',
          handler: (items) => {
            this.$emit('share', { vacancy: items[0] });
          }
        }
      ],
      rowHeight: 80,
      headerHeight: 40,
      itemsToLoad: 20,
    };
  },
  methods: {
    rowClicked(row) {
      if (this.showCheckbox) {
        this.$router.push(`/vacancies/vacancy/${row.id}/`);
      } else {
        this.$router.push(`/shr/v/${this.$route.params.token}/${row.id}`);
      }
    },
  },
  watch: {
    checkedItemIds(newVal) {
      let filtered_items = newVal
        .filter(item => item.access_type !== 'read');
      let mapped_items = filtered_items.map(item => item.id);
      this.$emit('change-checkboxes', mapped_items);
    },
  },
};
</script>

<style lang="scss">
.vacancies-table {
  margin-top: 36px;

  .vacancy-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    max-width: 300px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 6px;
  }

  .owner-name {
  }

  .row_company {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: rgba(var(--page-color-main-rgb), .4);
  }

  .row_fee {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    .currency {
      padding-left: 5px;
      font-size: 13px;
      color: rgba(var(--page-color-main-rgb), .4);
    }
  }

  .vacancy-candidates {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: auto;

    &_text {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: rgba(var(--page-color-main-rgb), .25);

      .count {
        color: var(--page-color-main);
      }
    }

    &_list {
      display: flex;
      align-items: center;
      margin-right: 22px;

      & > * {
        margin-right: -10px;
      }
    }

    .candidate-media {
      width: 32px;
      height: 32px;
      border: 2px solid #FFFFFF;
    }
  }

  .row_more {

    .icon-wrap {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
    }

    .icon {
      color: rgba(var(--page-color-main-rgb), .25);
      font-size: 24px;

      &:hover {
        color: var(--page-color-main);
      }
    }
  }
}
</style>
